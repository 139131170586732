
  import moment from 'moment'
  import { getAreaChongCiDetail, getAreaChongCiList, getDetailList, getTodayChongCiList, getTotal } from '@api/chongci'
  import NoData from '@components/NoData/NoData.vue'
  import { getThousandsValue } from '@utils/utils'
  import {
    getJsrAreaChongCiDetail,
    getJsrAreaChongCiList,
    getJsrDetailList,
    getJsrTodayChongCiList,
    getJsrTotal
  } from '@api/chongci_income'

  export default {
    name: 'ChongciIndex',
    components: { NoData },
    props: {},
    data() {
      return {
        noDataMessage: '暂无冲刺统计',
        isLoading: false,
        projectId: '', // 查看详情时的projectId
        defaultDate: '', // 限制时间
        /*
          showType
        * 0不显示 为缺省页
        * 1为当前日冲刺
        * 2为不同日冲刺
        * 3为明细页
        * 4为片区视角页
        * 5为片区明细列表
        */
        showType: 0,
        startDate: '',
        endDate: '',
        dateType: '',
        tableList: [],
        detailList: [],
        areaList: [],
        areaDetailList: [],
        conditionList: ['昨日', '今日', '上周', '本周', '上月', '本月'],
        conditionCheckName: '', // 选中时按钮名称
        statisticsType: '1',
        startDateInfo: {
          show: false,
          date: [],
          minDate: new Date('2023/09/01'),
          maxDate: new Date('2024/12/31'),
          defaultDate: new Date()
        },
        endDateInfo: {
          show: false,
          date: [],
          minDate: new Date('2023/09/01'),
          maxDate: new Date('2024/12/31'),
          defaultDate: new Date()
        }
      }
    },
    computed: {
      completeType: function() {
        return function(item) {
          if (Number(item.targetVal) < 0) {
            return 'red'
          }
          if (Number(item.completeProgress) >= 100) {
            return 'red'
          } else if (Number(item.completeProgress) < 100) {
            return 'green'
          }
        }
      },
      projectType: function() {
        return function(item) {
          if (item.projectName === '合计') {
            return 'red'
          } else {
            return 'blue'
          }
        }
      },
      projectDetailType: function() {
        return function(item) {
          if (item.projectName === '合计') {
            return 'red'
          } else {
            return ''
          }
        }
      }
    },
    watch: {},
    async created() {
      let date = this.$route.query.date
      if (date) {
        this.defaultDate = moment(date).format('YYYY-MM-DD')
        this.startDate = moment(date).format('YYYY-MM-DD')
        this.endDate = moment(date).format('YYYY-MM-DD')
        this.dateType = 'day'
        await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
      }
    },
    mounted() {
    },
    methods: {
      getThousandsValue,
      isMinus(val) {
        let sign = Math.sign(val)
        if (sign === -1) {
          return true
        } else {
          return false
        }
      },
      // 返回一个特定的 DOM 节点，作为挂载的父节点
      getContainer() {
        return document.querySelector('body')
      },
      async initData() {
        await this.getTableList()
      },
      handleChange(val) {
        this.initData()
      },
      async getTableList({ isShowLoading } = { isShowLoading: true }) {
        try {
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          // 判断是否等于url给定时间
          let response
          if ((this.startDate == this.defaultDate) && (this.endDate == this.defaultDate)) {
            this.showType = 1
            let day = moment(this.defaultDate).format('YYYYMMDD')
            response = this.statisticsType === '1'
              ? await getTodayChongCiList({ day, dateType: this.dateType })
              : await getJsrTodayChongCiList({ day, dateType: this.dateType })
          } else {
            this.showType = 2
            let startDate = moment(this.startDate).format('YYYYMMDD')
            let endDate = moment(this.endDate).format('YYYYMMDD')
            response = this.statisticsType === '1'
              ? await getTotal({ startDate, endDate, dateType: this.dateType })
              : await getJsrTotal({ startDate, endDate, dateType: this.dateType })
          }
          if (`${response.data.code}` == 200) { // 请求成功
            console.log(this.showType)
            this.tableList = response.data.result || []
          } else {
            // 请求失败
            this.showType = 0 // 展示缺省页
            await this.handleCallback({
              isError: true,
              errorMessage: response.msg || '获取失败!'
            })
            return Promise.reject(new Error(response.msg || '获取失败!'))
          }
        } catch (error) {
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取失败!'
          })
          return Promise.reject(error)
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      disposeLeftVal(item) {
        let leftVal = Math.abs(Number(item.leftVal))
        if (Number(item.targetVal) < 0) {
          return '超额 ' + this.getThousandsValue(leftVal)
        }

        if (Number(item.completeProgress) >= 100) {
          return '超额 ' + this.getThousandsValue(leftVal)
        } else if (Number(item.completeProgress) < 100) {
          return this.getThousandsValue(leftVal)
        }
      },
      async handleStartCalendar(date) {
        this.startDateInfo.show = false
        this.dateType = 'custom'
        this.conditionCheckName = ''
        this.startDateInfo.date = date
        this.endDateInfo.minDate = date // 结束日期不能大于启始日期
        this.startDate = moment(date).format('YYYY-MM-DD')
        let start = new Date(this.startDate).getTime()
        let end = new Date(this.endDate).getTime()
        if (start > end) { // 开始时间大于结束时间时：结束时间清空
          this.endDate = ''
          return
        }
        if (this.showType === 3) {
          await this.getDetail()
        } else if (this.showType === 4) {
          await this.getAreaList()
        } else if (this.showType === 5) {
          await this.getAreaDetailList()
        } else {
          await this.getTableList()
        }
      },
      async handleEndCalendar(date) {
        this.endDateInfo.show = false
        this.dateType = 'custom'
        this.conditionCheckName = ''
        this.endDateInfo.date = date
        this.endDate = moment(date).format('YYYY-MM-DD')
        if (this.showType === 3) {
          await this.getDetail()
        } else if (this.showType === 4) {
          await this.getAreaList()
        } else if (this.showType === 5) {
          await this.getAreaDetailList()
        } else {
          await this.getTableList()
        }
      },
      async handleCondition(type) {
        this.conditionCheckName = type
        let weekOfDay = new Date().getDay() // 今天星期几
        switch (type) {
          case '昨日':
            this.startDate = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')
            this.endDate = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')
            this.dateType = 'day'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
          case '今日':
            this.startDate = moment(new Date()).format('YYYY-MM-DD')
            this.endDate = moment(new Date()).format('YYYY-MM-DD')
            this.dateType = 'day'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
          case '上周':
            this.startDate = moment(new Date()).subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
            this.endDate = moment(new Date()).subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
            this.dateType = 'week'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
          case '本周':
            this.startDate = moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD')
            this.endDate = moment(new Date()).endOf('isoWeek').format('YYYY-MM-DD')
            this.dateType = 'week'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
          case '上月':
            this.startDate = moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
            this.endDate = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
            this.dateType = 'month'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
          case '本月':
            this.startDate = moment(new Date()).startOf('month').format('YYYY-MM-DD')
            this.endDate = moment(new Date()).endOf('month').format('YYYY-MM-DD')
            this.dateType = 'month'
            await this.setDateRange() // 限制结束时间选择要大于开始时间、设置默认值
            break
        }
      },
      // 限制结束时间选择要大于开始时间、设置默认值
      async setDateRange() {
        this.endDateInfo.minDate = new Date(this.startDate) // 结束时间选择要大于开始时间
        this.startDateInfo.defaultDate = new Date(this.startDate)
        this.endDateInfo.defaultDate = new Date(this.endDate)
        if (this.showType === 3) {
          await this.getDetail()
        } else if (this.showType === 4) {
          await this.getAreaList()
        } else if (this.showType === 5) {
          await this.getAreaDetailList()
        } else {
          await this.getTableList()
        }
      },
      // 项目信息跳转
      handleProject(row) {
        this.projectId = row.projectId
        this.showType = 3
        this.getDetail()
      },
      // 片区列表跳转
      handleArea() {
        this.showType = 4
        // // 如果是url上的时间  就把片区视角的时间往前推一天
        // let date = moment(this.$route.query.date).format('YYYY-MM-DD')
        // this.startDate = moment(date).format('YYYY-MM-DD')
        // this.endDate = moment(date).format('YYYY-MM-DD')
        // if (date == this.startDate && date == this.endDate) {
        //   this.startDate = moment(date).subtract(1, 'days').format('YYYY-MM-DD')
        //   this.endDate = moment(date).subtract(1, 'days').format('YYYY-MM-DD')
        // }
        this.getAreaList()
      },
      // 片区列表明细跳转
      handleAreaProject(row) {
        this.projectId = row.projectId
        this.showType = 5
        this.getAreaDetailList()
      },
      async getDetail({ isShowLoading } = { isShowLoading: true }) {
        try {
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let params = {
            projectId: this.projectId,
            dateType: this.dateType,
            startDate: moment(this.startDate).format('YYYYMMDD'),
            endDate: moment(this.endDate).format('YYYYMMDD')
          }
          let res = this.statisticsType === '1'
            ? await getDetailList(params)
            : await getJsrDetailList(params)
          if (res.data.code === 200) {
            this.detailList = res.data.result
          } else {
            // 请求失败
            this.showType = 0 // 展示缺省页
            await this.handleCallback({
              isError: true,
              errorMessage: res.msg || '获取失败!'
            })
            return Promise.reject(new Error(res.msg || '获取失败!'))
          }
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async getAreaList({ isShowLoading } = { isShowLoading: true }) {
        try {
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let params = {
            dateType: this.dateType,
            startDate: moment(this.startDate).format('YYYYMMDD'),
            endDate: moment(this.endDate).format('YYYYMMDD')
          }
          const res = this.statisticsType === '1'
            ? await getAreaChongCiList(params)
            : await getJsrAreaChongCiList(params)
          if (res.data.code === 200) {
            this.areaList = res.data.result
          } else {
            // 请求失败
            this.showType = 0 // 展示缺省页
            await this.handleCallback({
              isError: true,
              errorMessage: res.msg || '获取失败!'
            })
            return Promise.reject(new Error(res.msg || '获取失败!'))
          }
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async getAreaDetailList({ isShowLoading } = { isShowLoading: true }) {
        try {
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let params = {
            dateType: this.dateType,
            projectId: this.projectId,
            startDate: moment(this.startDate).format('YYYYMMDD'),
            endDate: moment(this.endDate).format('YYYYMMDD')
          }
          const res = this.statisticsType === '1'
            ? await getAreaChongCiDetail(params)
            : await getJsrAreaChongCiDetail(params)
          if (res.data.code === 200) {
            this.areaDetailList = res.data.result
          } else {
            // 请求失败
            this.showType = 0 // 展示缺省页
            await this.handleCallback({
              isError: true,
              errorMessage: res.msg || '获取失败!'
            })
            return Promise.reject(new Error(res.msg || '获取失败!'))
          }
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      handleBack() {
        this.initData()
      },
      handleAreaBack() {
        this.showType = 4
        this.getAreaList()
      }
    }
  }
