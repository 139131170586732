var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "select"
  }, [_c('span', [_vm._v("统计时间")]), _c('span', {
    staticClass: "start_date",
    on: {
      "click": function ($event) {
        _vm.startDateInfo.show = true;
      }
    }
  }, [_c('van-image', {
    staticClass: "img_icon",
    attrs: {
      "width": "20",
      "height": "20",
      "src": require('../../assets/images/date.png')
    }
  }), _c('span', {
    staticClass: "date",
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v(_vm._s(_vm.startDate))])], 1), _c('span', [_vm._v("至")]), _c('span', {
    staticClass: "end_date",
    on: {
      "click": function ($event) {
        _vm.endDateInfo.show = true;
      }
    }
  }, [_c('van-image', {
    staticClass: "img_icon",
    attrs: {
      "width": "20",
      "height": "20",
      "src": require('../../assets/images/date.png')
    }
  }), _c('span', {
    staticClass: "date",
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v(_vm._s(_vm.endDate))])], 1)]), _c('div', {
    staticClass: "Condition"
  }, _vm._l(_vm.conditionList, function (item, idx) {
    return _c('div', {
      key: idx,
      class: _vm.conditionCheckName === item ? 'button-green' : 'button-red'
    }, [_c('van-button', {
      attrs: {
        "type": "default",
        "size": "small"
      },
      on: {
        "click": function ($event) {
          return _vm.handleCondition(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }), 0), _vm.showType === 1 || _vm.showType === 2 ? _c('van-tabs', {
    staticStyle: {
      "background-color": "#F8F8F8"
    },
    on: {
      "change": _vm.handleChange
    },
    model: {
      value: _vm.statisticsType,
      callback: function ($$v) {
        _vm.statisticsType = $$v;
      },
      expression: "statisticsType"
    }
  }, [_c('van-tab', {
    attrs: {
      "name": "1",
      "title": "总营收"
    }
  }), _c('van-tab', {
    attrs: {
      "name": "2",
      "title": "净收入"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "content-table"
  }, [_vm.showType === 0 && _vm.isLoading === false ? _c('no-data', {
    attrs: {
      "msg": _vm.noDataMessage
    },
    scopedSlots: _vm._u([{
      key: "btn",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius",
          on: {
            "click": _vm.initData
          }
        }, [_vm._v(" 刷新重试 ")])];
      },
      proxy: true
    }], null, false, 2894671784)
  }) : _vm._e(), _vm.showType === 1 ? _c('table', {
    staticClass: "spec_table table"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.tableList, function (item) {
    return _c('tr', {
      key: item.id
    }, [_c('td', {
      class: _vm.projectType(item),
      on: {
        "click": function ($event) {
          return _vm.handleProject(item);
        }
      }
    }, [_vm._v(_vm._s(item.projectName))]), _c('td', [_vm.isMinus(item.targetVal) ? _c('span', {
      staticStyle: {
        "color": "red"
      }
    }, [_vm._v("已完成本月目标")]) : _c('span', [_vm._v(_vm._s(_vm.getThousandsValue(item.targetVal)))])]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeVal)))]), _c('td', {
      class: _vm.completeType(item)
    }, [_vm.isMinus(item.targetVal) ? _c('span', {
      staticStyle: {
        "color": "red"
      }
    }, [_vm._v("已完成本月目标")]) : _c('span', [_vm._v(_vm._s(item.completeProgress) + " %")])]), _c('td', {
      class: _vm.completeType(item)
    }, [_vm._v(_vm._s(_vm.disposeLeftVal(item)))]), _c('td', [_vm.isMinus(item.todayTargetDay) ? _c('span', {
      staticStyle: {
        "color": "red"
      }
    }, [_vm._v("已完成本月目标")]) : _c('span', [_vm._v(_vm._s(_vm.getThousandsValue(item.todayTargetDay)))])]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeValLastYear)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeValLastDay)))])]);
  }), 0)]) : _vm._e(), _vm.showType === 2 ? _c('table', {
    staticClass: "spec_table table"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.tableList, function (item) {
    return _c('tr', {
      key: item.id
    }, [_c('td', {
      class: _vm.projectType(item),
      on: {
        "click": function ($event) {
          return _vm.handleProject(item);
        }
      }
    }, [_vm._v(_vm._s(item.projectName))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.targetVal)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeVal)))]), _c('td', {
      class: _vm.completeType(item)
    }, [_vm._v(_vm._s(item.completeProgress) + " %")]), _c('td', {
      class: _vm.completeType(item)
    }, [_vm._v(_vm._s(_vm.disposeLeftVal(item)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeValLastYear)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeValLastDay)))])]);
  }), 0)]) : _vm._e(), _vm.showType === 3 ? _c('van-button', {
    staticStyle: {
      "margin-bottom": "5px"
    },
    attrs: {
      "icon": "arrow-left",
      "type": "default",
      "size": "mini"
    },
    on: {
      "click": _vm.handleBack
    }
  }, [_vm._v(" 返回 ")]) : _vm._e(), _vm.showType === 3 ? _c('table', {
    staticClass: "spec_table table"
  }, [_vm._m(2), _c('tbody', _vm._l(_vm.detailList, function (item) {
    return _c('tr', {
      key: item.id
    }, [_c('td', {
      class: _vm.projectDetailType(item)
    }, [_vm._v(_vm._s(item.projectName))]), _c('td', [_vm._v(_vm._s(item.day))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.targetVal)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeVal)))]), _c('td', {
      class: _vm.completeType(item)
    }, [_vm._v(_vm._s(item.completeProgress) + " %")]), _c('td', {
      class: _vm.completeType(item)
    }, [_vm._v(_vm._s(_vm.disposeLeftVal(item)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeValLastYear)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeValLastDay)))])]);
  }), 0)]) : _vm._e(), _vm.showType === 4 ? _c('van-button', {
    staticStyle: {
      "margin-bottom": "5px"
    },
    attrs: {
      "icon": "arrow-left",
      "type": "default",
      "size": "mini"
    },
    on: {
      "click": _vm.handleBack
    }
  }, [_vm._v(" 返回 ")]) : _vm._e(), _vm.showType === 4 ? _c('table', {
    staticClass: "spec_table table"
  }, [_vm._m(3), _c('tbody', _vm._l(_vm.areaList, function (item) {
    return _c('tr', {
      key: item.id
    }, [_c('td', {
      class: _vm.projectType(item),
      on: {
        "click": function ($event) {
          return _vm.handleAreaProject(item);
        }
      }
    }, [_vm._v(_vm._s(item.projectName))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.targetVal)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeVal)))]), _c('td', {
      class: _vm.completeType(item)
    }, [_vm._v(_vm._s(item.completeProgress) + " %")]), _c('td', {
      class: _vm.completeType(item)
    }, [_vm._v(_vm._s(_vm.disposeLeftVal(item)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeValLastYear)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeValLastDay)))])]);
  }), 0)]) : _vm._e(), _vm.showType === 5 ? _c('van-button', {
    staticStyle: {
      "margin-bottom": "5px"
    },
    attrs: {
      "icon": "arrow-left",
      "type": "default",
      "size": "mini"
    },
    on: {
      "click": _vm.handleAreaBack
    }
  }, [_vm._v(" 返回 ")]) : _vm._e(), _vm.showType === 5 ? _c('table', {
    staticClass: "spec_table table"
  }, [_vm._m(4), _c('tbody', _vm._l(_vm.areaDetailList, function (item) {
    return _c('tr', {
      key: item.id
    }, [_c('td', {
      class: _vm.projectDetailType(item)
    }, [_vm._v(_vm._s(item.projectName))]), _c('td', [_vm._v(_vm._s(item.day))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.targetVal)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeVal)))]), _c('td', {
      class: _vm.completeType(item)
    }, [_vm._v(_vm._s(item.completeProgress) + " %")]), _c('td', {
      class: _vm.completeType(item)
    }, [_vm._v(_vm._s(_vm.disposeLeftVal(item)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeValLastYear)))]), _c('td', [_vm._v(_vm._s(_vm.getThousandsValue(item.completeValLastDay)))])]);
  }), 0)]) : _vm._e()], 1)]), _vm.showType != 4 ? _c('div', {
    staticClass: "area",
    on: {
      "click": _vm.handleArea
    }
  }, [_vm._v(" 片区视角 ")]) : _vm._e(), _c('van-calendar', {
    ref: "rentalDateCalendar",
    attrs: {
      "default-date": _vm.startDateInfo.defaultDate,
      "get-container": _vm.getContainer,
      "max-date": _vm.startDateInfo.maxDate,
      "min-date": _vm.startDateInfo.minDate,
      "color": "#D1A36F",
      "type": "single"
    },
    on: {
      "confirm": _vm.handleStartCalendar
    },
    model: {
      value: _vm.startDateInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.startDateInfo, "show", $$v);
      },
      expression: "startDateInfo.show"
    }
  }), _c('van-calendar', {
    ref: "rentalDateCalendar",
    attrs: {
      "default-date": _vm.endDateInfo.defaultDate,
      "get-container": _vm.getContainer,
      "max-date": _vm.endDateInfo.maxDate,
      "min-date": _vm.endDateInfo.minDate,
      "color": "#D1A36F",
      "type": "single"
    },
    on: {
      "confirm": _vm.handleEndCalendar
    },
    model: {
      value: _vm.endDateInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.endDateInfo, "show", $$v);
      },
      expression: "endDateInfo.show"
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('th', [_vm._v("项目名称")]), _c('th', [_vm._v("昨日滚动目标")]), _c('th', [_vm._v("昨日完成")]), _c('th', [_vm._v("昨日完成进度")]), _c('th', [_vm._v("差额")]), _c('th', [_vm._v("今日滚动目标")]), _c('th', [_vm._v("同期营收")]), _c('th', [_vm._v("上个周期")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('th', [_vm._v("项目名称")]), _c('th', [_vm._v("预算目标")]), _c('th', [_vm._v("完成")]), _c('th', [_vm._v("进度")]), _c('th', [_vm._v("差额")]), _c('th', [_vm._v("同期营收")]), _c('th', [_vm._v("上个周期")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('th', [_vm._v("项目名称")]), _c('th', [_vm._v("日期")]), _c('th', [_vm._v("预算目标")]), _c('th', [_vm._v("完成")]), _c('th', [_vm._v("进度")]), _c('th', [_vm._v("差额")]), _c('th', [_vm._v("同期营收")]), _c('th', [_vm._v("上个周期")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('th', [_vm._v("片区名称")]), _c('th', [_vm._v("预算目标")]), _c('th', [_vm._v("完成")]), _c('th', [_vm._v("进度")]), _c('th', [_vm._v("差额")]), _c('th', [_vm._v("同期营收")]), _c('th', [_vm._v("上个周期")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('th', [_vm._v("项目名称")]), _c('th', [_vm._v("日期")]), _c('th', [_vm._v("预算目标")]), _c('th', [_vm._v("完成")]), _c('th', [_vm._v("进度")]), _c('th', [_vm._v("差额")]), _c('th', [_vm._v("同期营收")]), _c('th', [_vm._v("上个周期")])]);
}];
export { render, staticRenderFns };