import request from '@utils/request_gd';

/*
* 冲刺统计
* */
export const getTotal = (params = {}) => {
  return request({
    url: '/api/v1/chongCi/getTotal',
    method: 'get',
    params
  });
};
/*
* 当前日冲刺
* */
export const getTodayChongCiList = (params = {}) => {
  return request({
    url: '/api/v1/chongCi/getTodayChongCiList',
    method: 'get',
    params
  });
};
/*
* 冲刺明细列表
* */
export const getDetailList = (params = {}) => {
  return request({
    url: '/api/v1/chongCi/getList',
    method: 'get',
    params
  });
};

/*
* 区域列表
* */
export const getAreaChongCiList = (params = {}) => {
  return request({
    url: '/api/v1/chongCi/getAreaChongCiList',
    method: 'get',
    params
  });
};
/*
* 区域列表明细
* */
export const getAreaChongCiDetail = (params = {}) => {
  return request({
    url: '/api/v1/chongCi/getAreaChongCiDetailList',
    method: 'get',
    params
  });
};